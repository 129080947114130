import { useWeb3React } from "@web3-react/core";
import { useCallback, useMemo } from "react";
import { TOKEN_ADDRESS, USDT_ADDRESS } from "../constants/addresses";
import { MAX_UINT256 } from "../constants/misc";
import { useTokensPrice } from "../data/token";
import { useUsdtAllowance } from "../data/usdt";
import { getSigner } from "../utils";
import { calculateGasMargin } from "../utils/calculateGasMargin";
import { useUsdtContract } from "./useContract";

export const ApprovalState = {
  UNKNOWN: "UNKNOWN",
  NOT_APPROVED: "NOT_APPROVED",
  APPROVED: "APPROVED",
};

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(noOfTokens) {
  const { library, account, chainId } = useWeb3React();

  const tokenAddress = useMemo(() => USDT_ADDRESS[chainId], [chainId]);
  const spender = useMemo(() => TOKEN_ADDRESS[chainId], [chainId]);
  const amountToApprove = useTokensPrice(noOfTokens);
  const currentAllowance = useUsdtAllowance(account ?? undefined, spender);

  // check the current approval status
  const approvalState = useMemo(() => {
    if (!tokenAddress || !amountToApprove || !spender)
      return ApprovalState.UNKNOWN;

    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [tokenAddress, amountToApprove, spender, currentAllowance]);

  const usdtContract = useUsdtContract(false);

  const approve = useCallback(async () => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily");
      return;
    }

    if (!chainId) {
      console.error("no chainId");
      return;
    }

    if (!usdtContract) {
      console.error("usdtContract is null");
      return;
    }

    if (!amountToApprove) {
      console.error("missing amount to approve");
      return;
    }

    if (!spender) {
      console.error("no spender");
      return;
    }

    const signer = getSigner(library, account);

    let useExact = false;
    const estimatedGas = await usdtContract
      .connect(signer)
      .estimateGas.approve(spender, MAX_UINT256)
      .catch(() => {
        // general fallback for tokens who restrict approval amounts
        useExact = true;
        return usdtContract
          .connect(signer)
          .estimateGas.approve(spender, amountToApprove);
      });

    // actual txn
    return usdtContract
      .connect(signer)
      .approve(spender, useExact ? amountToApprove : MAX_UINT256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.debug("Failed to approve token", error);
        throw error;
      });
  }, [
    approvalState,
    usdtContract,
    spender,
    amountToApprove,
    chainId,
    account,
    library,
  ]);

  return [approvalState, approve];
}
