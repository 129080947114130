import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { isMobile } from "react-device-detect";
import { injected, walletconnect, walletlink } from "../connectors";
import {
  CoinBase,
  InjectedLogo,
  MetaMask,
  WalletConnect,
} from "../helpers/images";

export const injectedConnectorList = [
  {
    name: "Metamask",
    header: "Metamask",
    connector: injected,
    connectorType: InjectedConnector,
    icon: MetaMask,
    subheader: "Easy-to-use browser extension",
  },
  {
    name: "Injected",
    header: "Injected",
    connector: injected,
    connectorType: InjectedConnector,
    icon: InjectedLogo,
    subheader: "Injected web3 provider.",
  },
];

export const desktopWalletList = [
  {
    name: "Metamask",
    header: "Metamask",
    connector: injected,
    connectorType: InjectedConnector,
    icon: MetaMask,
    subheader: "Easy-to-use browser extension",
  },
  {
    name: "WalletConnect",
    header: "WalletConnect",
    connector: walletconnect,
    connectorType: WalletConnectConnector,
    icon: WalletConnect,
    subheader: "Connect to Trust Wallet, Rainbow Wallet and more...",
  },
  {
    name: "Coinbase",
    header: "Coinbase",
    connector: walletlink,
    connectorType: WalletLinkConnector,
    icon: CoinBase,
    subheader: "Use Coinbase Wallet app on mobile device",
  },
];

export const mobileWalletList = [
  {
    name: "WalletConnect",
    header: "WalletConnect",
    connector: walletconnect,
    connectorType: WalletConnectConnector,
    icon: WalletConnect,
    subheader: "Connect to Trust Wallet, Rainbow Wallet and more...",
  },
  {
    name: "Coinbase",
    header: "Open in Coinbase Wallet",
    icon: CoinBase,
    subheader: "Open in Coinbase Wallet app.",
    href: "https://go.cb-w.com/mtUDhEZPy1",
  },
];

export const installMetamask = {
  name: "Metamask",
  header: "Install Metamask",
  icon: MetaMask,
  link: "https://metamask.io/",
};

const isMetamask = window?.ethereum?.isMetaMask;

// if (isMobile) {
//   if (window?.ethereum) {
//     [isMetamask ? injectedConnectorList[0] : injectedConnectorList[1]];
//   } else {
//     mobileWalletList;
//   }
// } else if (!(window.web3 || window.ethereum)) {
//   [installMetamask, ...desktopWalletList];
// } else {
//   desktopWalletList;
// }

export const walletList = isMobile
  ? window?.ethereum
    ? [isMetamask ? injectedConnectorList[0] : injectedConnectorList[1]]
    : mobileWalletList
  : !(window.web3 || window.ethereum)
  ? [installMetamask, ...desktopWalletList]
  : desktopWalletList;
