import { Contract } from "@ethersproject/contracts";
import { getAddress } from "@ethersproject/address";
import { ZERO_ADDRESS } from "../constants/misc";

/**
 * returns the checksummed address if the address is valid, otherwise returns false
 */
export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

/**
 * shorten the checksummed version of the input address to have 0x + 4 characters at start and end
 */
export function shortenAddress(address, chars = 4) {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account);
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address, ABI, library, account) {
  if (!isAddress(address) || address === ZERO_ADDRESS) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
