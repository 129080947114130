import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import { shortenAddress } from "../utils";
import IdentIcon from "./IdentIcon";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import WalletModal from "./WalletModal/WalletModal";
import { injected, walletconnect, walletlink } from "../connectors";
import { useWalletModalToggle } from "../state/application/hooks";
import { WalletConnect, CoinBase } from "../helpers/images";

function StatusIcon({ connector }) {
  if (connector === injected) {
    return <IdentIcon diameter={20} />;
  } else if (connector === walletconnect) {
    return <img src={WalletConnect} alt={""} />;
  } else if (connector === walletlink) {
    return <img src={CoinBase} alt={""} />;
  }
  return null;
}
StatusIcon.propTypes = {
  connector: PropTypes.object,
};

const ConnectWalletBtn = () => {
  const screens = useWindowMediaQuery();
  const { account, connector, error } = useWeb3React();

  const toggleWalletModal = useWalletModalToggle();

  return (
    <>
      <CButton onClick={toggleWalletModal} variant="none" screens={screens}>
        {account ? (
          <div className="d-flex">
            <StatusIcon connector={connector} />
            <p className="mb-0" style={{ marginLeft: "10px" }}>
              {shortenAddress(account)}
            </p>
          </div>
        ) : error ? (
          <>
            {error instanceof UnsupportedChainIdError
              ? "Wrong Network"
              : "Error"}
          </>
        ) : (
          <>Connect Wallet</>
        )}
      </CButton>
      <WalletModal />
    </>
  );
};

const CButton = styled.button`
  background: none;
  border: none;
  outline: inherit;
  display: inline-block;
  padding: ${(props) => (props.screens.sm ? "5px 20px" : "12px 35px")};
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  text-transform: capitalize;
  color: ${colors.white};
  font-size: ${(props) => (props.screens.sm ? "25px" : "30px")};
  border-radius: 15px;
  background: linear-gradient(180deg, #ffb87b 0%, #f9831c 100%);
`;

export default ConnectWalletBtn;
