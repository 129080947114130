import { toBN } from "../utils/format";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const MAX_UINT256 = toBN(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

export const USDT_DECIMALS = 6;

// https://ipfs.io/ipfs/
// https://dartco.mypinata.cloud/
export const BASE_URI = "https://ipfs.io/ipfs/";
