import { createSlice } from "@reduxjs/toolkit";

// export interface Web3ManagerReducer {
//   readonly triedEager: boolean
//   readonly notActiveAndError: boolean
// }

const web3ManagerSlice = createSlice({
  name: "web3ManagerSlice",
  initialState: {
    triedEager: false,
    notActiveAndError: false,
  },
  reducers: {
    updateTriedEager: (state, action) => {
      const { triedEager } = action.payload;
      state.triedEager = triedEager;
    },

    updateNotActiveAndError: (state, action) => {
      const { notActiveAndError } = action.payload;
      state.notActiveAndError = notActiveAndError;
    },
  },
});

export const { updateTriedEager, updateNotActiveAndError } =
  web3ManagerSlice.actions;
export const web3ManagerReducer = web3ManagerSlice.reducer;
