import PropTypes from "prop-types";
import { Fragment } from "react";
import { injected, walletconnect } from "../../connectors";
import { walletList } from "../../constants/wallet";
import useWindowMediaQuery from "../../helpers/useMediaQueryHook";
import { ModalButton } from "./ModalStyled";

const PendingView = ({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}) => {
  const screens = useWindowMediaQuery();
  const isMetamask = window?.ethereum?.isMetaMask;

  return (
    <>
      {!error ? (
        <>
          <div className="text-center modal-title">
            <div>
              {connector === walletconnect
                ? "Scan QR code with a compatible wallet..."
                : "Initializing to "}
              <span className="title-color">
                {connector !== walletconnect &&
                  walletList.map(
                    (option) => option.connector === connector && option.name
                  )}
                ...
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-title">
            <div className="error text-center">Error</div>
          </div>
          <ModalButton
            screens={screens}
            className="mx-auto"
            onClick={() => {
              setPendingError(false);
              tryActivation(connector);
            }}
          >
            Try Again
          </ModalButton>
        </>
      )}

      <div className="modal-body d-flex justify-content-evenly align-items-center">
        {walletList.map((option, i) => {
          if (option.connector === connector) {
            // overwrite injected when needed
            if (option.connector === injected) {
              if (isMetamask && option.name !== "Metamask") {
                return null;
              }
              if (!isMetamask && option.name === "Metamask") {
                return null;
              }
            }

            return (
              <Fragment key={i}>
                <img src={option.icon} className="modal-icons" />
                <div className="modal-subtitle">
                  <span className="sub-title">{option.name}</span>
                  <br />
                  {option.subheader}
                </div>
              </Fragment>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default PendingView;
PendingView.propTypes = {
  connector: PropTypes.any,
  error: PropTypes.bool || false,
  setPendingError: PropTypes.func,
  tryActivation: PropTypes.func,
};
