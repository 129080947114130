import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useTokensPrice } from "../data/token";
import { useUsdtBalance } from "../data/usdt";
import isZero from "../utils/isZero";
import { useTokenContract } from "./useContract";
import { getSigner } from "../utils";
import { calculateGasMargin } from "../utils/calculateGasMargin";
import { useETHBalance } from "../state/wallet/hooks";

export const MintState = {
  ELIGIBLE: "ELIGIBLE",
  INSUFFICIENT_USDT: "INSUFFICIENT_USDT",
  INSUFFICIENT_ETH: "INSUFFICIENT_ETH",
  UNKNOWN: "UNKNOWN",
};

export const useMintCallback = (noOfTokens = 1) => {
  const { chainId, account, library } = useWeb3React();

  const ethBalance = useETHBalance();
  const price = useTokensPrice(noOfTokens);
  const usdtBalance = useUsdtBalance();

  const mintState = useMemo(() => {
    // if price and usdtBalance not present
    if (!price || !usdtBalance || !ethBalance) return MintState.UNKNOWN;

    // if usdt is less than price
    if (isZero(usdtBalance.toHexString()) || usdtBalance.lt(price))
      return MintState.INSUFFICIENT_USDT;

    // if ethBalance is zero
    if (isZero(ethBalance.toHexString())) return MintState.INSUFFICIENT_ETH;

    return MintState.ELIGIBLE;
  }, [price, usdtBalance, ethBalance]);

  const contract = useTokenContract(false);

  const mint = useCallback(async () => {
    if (!chainId) {
      console.error("no chainId");
      return;
    }

    if (!contract) {
      console.error("contract is null");
      return;
    }

    const signer = getSigner(library, account);

    const estimatedGas = await contract
      .connect(signer)
      .estimateGas.mint(price, noOfTokens);

    // actual txn
    const response = await contract.connect(signer).mint(price, noOfTokens, {
      gasLimit: calculateGasMargin(estimatedGas),
    });

    return response;
  }, [chainId, contract, library, account, price, noOfTokens]);

  return [mintState, mint];
};
