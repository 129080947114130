import { Modal, Spinner } from "react-bootstrap";
import { ModalButton, ModalStyled } from "../WalletModal/ModalStyled";
import useWindowMediaQuery from "../../helpers/useMediaQueryHook";

export function ConfirmationPendingContent() {
  const screens = useWindowMediaQuery();

  return (
    <ModalStyled screens={screens}>
      <Modal.Body className="text-center">
        <div className="modal-subtitle">
          <div className="mx-auto d-flex justify-content-center align-items-center">
            <Spinner animation="border" />{" "}
            <p
              className="mb-0"
              style={{
                marginLeft: "20px",
              }}
            >
              Confirming Transaction
            </p>
          </div>
        </div>

        <ModalButton
          className="mt-4 mb-4 mx-auto"
          screens={screens}
          small={true}
        >
          confirm this transaction in your wallet
        </ModalButton>
      </Modal.Body>
    </ModalStyled>
  );
}
