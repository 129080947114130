import styled from "styled-components";
import Img404 from "../assets/images/404.png";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const ErrorPage = () => {
  const screens = useWindowMediaQuery();
  var sectionStyle = {
    backgroundImage: `url(${Img404})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: `${screens.sm ? "100%" : "60%"}`,
    backgroundPosition: "center",
  };
  return (
    <ErrorPageStyled screens={screens}>
      <div
        className="error-container d-flex align-items-center justify-content-center"
        style={sectionStyle}
      >
        <div>
          <div className="heading">Page not found</div>
          <div className="error-btn mx-auto">
            <div className="back-btn"> Back to homepage</div>
          </div>
        </div>
      </div>
    </ErrorPageStyled>
  );
};

const ErrorPageStyled = styled.div`
  .error-container {
    margin-bottom: 100px;
    height: 60vh;
    @media only screen and (max-width: 767px) {
      height: 40vh;
    }
  }
  .error-btn {
    width: 209px;
    margin-top: 30px;
    background: #5865f2;
    border-radius: 8px;
  }
  .back-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 30px;
    text-align: center;
    color: #ffffff;
  }
  .heading {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => (props.screens.sm ? "30px" : "48px")};
    margin-top: ${(props) => (props.screens.sm ? "120px" : "")};
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #000000;
  }
`;

export default ErrorPage;
