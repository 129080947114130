import PropTypes from "prop-types";
import useCopyClipboard from "../hooks/useCopyClipboard";
import { Tick, Copy } from "../helpers/images";

const CopyHelper = ({ toCopy, children }) => {
  const [isCopied, setCopied] = useCopyClipboard();

  return (
    <div onClick={() => setCopied(toCopy)}>
      {isCopied ? <Tick /> : <Copy />}{" "}
      <span>{isCopied ? "Address Copied" : children}</span>
    </div>
  );
};

export default CopyHelper;
CopyHelper.propTypes = {
  toCopy: PropTypes.node,
  children: PropTypes.node,
};
