import styled from "styled-components";
import Papa from "papaparse";
import Dropzone from "react-dropzone";
import { CharmTick, CsvImg, ErrorImg } from "../helpers/images";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import ConnectWalletBtn from "../components/ConnectWalletBtn";
import { colors } from "../helpers/colors";
import { useCallback, useMemo, useState } from "react";
import { parseAccountMap } from "../utils/parseAccountMap";
import { handlePinJSONToIPFS } from "../utils/pinataUtils";
import { SetRootState, useSetRootCallback } from "../hooks/useSetRootCallback";
import { useWeb3React } from "@web3-react/core";
import TransactionConfirmationModal from "../components/TransactionConfirmationModal/TransactionConfirmationModal";
import {
  useNotActiveAndError,
  useTriedEager,
} from "../state/web3Manager/hooks";

const Admin = () => {
  const screens = useWindowMediaQuery();

  // tried eager and notactiveanderror hooks from redux
  const triedEager = useTriedEager();
  const notActiveAndError = useNotActiveAndError();

  const [root, setRoot] = useState(null);
  const [ipfsHash, setIpfsHash] = useState(null);
  // single state for modal and loading
  const [{ showConfirm, attemptingTxn, txHash, errorMessage }, setModalState] =
    useState({
      showConfirm: false,
      attemptingTxn: false,
      txHash: undefined,
      errorMessage: undefined,
    });
  const { active } = useWeb3React();
  const [setRootState, setRootCallback] = useSetRootCallback(root, ipfsHash);

  const generateTreeAndUpload = useCallback(async (accounts) => {
    if (!accounts.data) throw new Error("No accounts");

    const flatData = accounts?.data?.flatMap((d) => d)?.filter((d) => d !== "");
    // console.log("flatData >>> ", flatData);

    const tree = parseAccountMap(flatData);
    // console.log("tree >>> ", tree);
    setRoot(tree.merkleRoot);

    const response = await handlePinJSONToIPFS(tree);
    // console.log("response >>> ", response);
    const cid = response?.IpfsHash;
    // console.log("cid >>> ", cid);
    setIpfsHash(cid);
  }, []);

  const [uploadCsvMessage, setUploadCsvMessage] = useState("Upload CSV");
  const [uploadCsvStatus, setUploadCsvStatus] = useState(0);

  const handleAcceptedFiles = useCallback(
    (files) => {
      if (!files) {
        setUploadCsvStatus(0);
        return;
      }
      setUploadCsvMessage("CSV Uploaded!");
      setUploadCsvStatus(1);
      Papa.parse(files[0], {
        complete: generateTreeAndUpload,
      });
    },
    [generateTreeAndUpload]
  );

  const disableBtn = useMemo(() => {
    if (!active) return true;
    if (!root) return true;
    if (!ipfsHash) return true;
    if (setRootState === SetRootState.NOT_ELIGIBLE) return true;
    if (setRootState === SetRootState.INSUFFICIENT_BALANCE) return true;
  }, [active, root, ipfsHash, setRootState]);

  const btnText = useMemo(() => {
    if (setRootState === SetRootState.NOT_ELIGIBLE) return "Only Owner!";
    if (setRootState === SetRootState.INSUFFICIENT_BALANCE)
      return "Insufficient Balance";
    return "Submit";
  }, [setRootState]);

  const handleSetRoot = useCallback(async () => {
    if (!root || !ipfsHash) return;

    setModalState({
      showConfirm: true,
      attemptingTxn: true,
      txHash: undefined,
      errorMessage: undefined,
    });

    try {
      if (setRootState === SetRootState.ELIGIBLE) {
        const response = await setRootCallback();
        console.log("response >>> ", response);

        setModalState((prev) => ({
          ...prev,
          txHash: response.hash,
        }));

        const resWait = await response.wait();
        if (resWait?.status === 1) {
          setModalState((prev) => ({
            ...prev,
            attemptingTxn: false,
          }));
        } else {
          setModalState((prev) => ({
            ...prev,
            showConfirm: false,
          }));
        }
      }
    } catch (error) {
      setModalState((prev) => ({
        ...prev,
        attemptingTxn: false,
        errorMessage: undefined,
      }));

      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        console.log("error in handleSetRootCallback -> ", error);
      }
    }
  }, [root, ipfsHash, setRootState, setRootCallback]);

  const handleConfirmDismiss = useCallback(() => {
    setModalState((prev) => ({
      ...prev,
      showConfirm: false,
      attemptingTxn,
      txHash,
    }));
  }, [attemptingTxn, txHash]);

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    console.log("triedEager not connected!");
    return null;
  }

  if (notActiveAndError) {
    console.log("not active and error!");
    return (
      <AdminStyled screens={screens}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div>
            <img src={ErrorImg} alt="error" className="error-img" />
          </div>
          <div className="note">
            Oops! An unknown error occurred.
            <br></br>Please refresh the page, or visit from another browser or
            device.
          </div>
        </div>
      </AdminStyled>
    );
  }

  return (
    <AdminStyled screens={screens}>
      <div
        style={{ marginTop: "80px", marginBottom: "50px" }}
        className="text-center"
      >
        <ConnectWalletBtn />
      </div>
      <div
        style={{ marginBottom: "60px" }}
        className="d-flex align-items-center justify-content-center flex-wrap"
      >
        <Dropzone
          accept={["text/csv", "text/plain", "application/vnd.ms-excel"]}
          maxFiles={1}
          onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <button className="csv-btn" {...getRootProps()}>
              {uploadCsvStatus ? (
                <>
                  <img src={CharmTick} alt="csv-icon" />
                  <div className="csv-text">{uploadCsvMessage}</div>
                  <input {...getInputProps()} />
                </>
              ) : (
                <>
                  <img src={CsvImg} alt="csv-icon" />
                  <div className="csv-text">{uploadCsvMessage}</div>
                  <input {...getInputProps()} />
                </>
              )}
            </button>
          )}
        </Dropzone>
        <button
          className={`${
            disableBtn ? "admin-btn disabled" : "admin-btn notDisabled"
          }`}
          disabled={disableBtn}
          onClick={handleSetRoot}
        >
          {btnText}
        </button>
      </div>
      <div className="note">
        <span className="note-bold">Note:</span> Please upload a correct CSV
        file and hit submit to change the merkle root in the smart contract,
        only owner can access this function
      </div>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleConfirmDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        errorText={errorMessage}
      />
    </AdminStyled>
  );
};

const AdminStyled = styled.div`
  .admin-btn {
    background: none;
    border: none;
    outline: inherit;
    margin-left: ${(props) => (props.screens.sm ? "" : "20px")};
    padding: 0px 70px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20.423px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 49px;
    text-align: center;
    text-transform: capitalize;
    color: ${colors.white};
  }
  .disabled {
    background: #9ca2e8;
    border: 2px solid #cbcbcb;
    color: ${colors.white};
    box-shadow: none;
  }
  .notDisabled {
    background: #5865f2;
    cursor: pointer;
  }

  .note {
    font-family: Poppins;
    font-style: normal;
    width: 60%;
    margin: auto;
    margin-bottom: 150px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    .note-bold {
      font-weight: 600;
    }
    text-align: center;
    color: ${colors.textColor};
  }
  .csv-btn {
    padding: ${(props) => (props.screens.sm ? "10px 30px" : "10px 25px")};
    margin-bottom: ${(props) => (props.screens.sm ? "40px" : "")};
    margin-right: ${(props) => (props.screens.sm ? "" : "20px")};
    text-align: center;
    background: #ebebeb;
    border-radius: 6px;
    border: 1px solid #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .csv-text {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      margin-left: 12px;
    }
  }

  .error-img {
    width: 100%;
`;

export default Admin;
