import styled from "styled-components";
import { Col } from "react-bootstrap";
import { bike2 } from "../helpers/images";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const Section2 = () => {
  const screens = useWindowMediaQuery();
  const textToShow = (
    <p>
      An urban bike with an electric motor for assisted pedaling inspired by
      nature, which takes it&lsquo;s cue from the lines of a dragonfly, emboding
      lightness, freedom, and speed; The power of a horse for the strength and
      the beauty of a swan for the elegance of it&lsquo;s lines
    </p>
  );

  const imgToShow = <img src={bike2} className="img" alt="bike2" />;
  return (
    <Section2Styled screens={screens}>
      <div className="d-flex align-items-center flex-wrap">
        {screens.sm ? (
          <>
            <Col className="mt-5 mb-4 text">{textToShow}</Col>
            <Col className="text-align-right">{imgToShow}</Col>
          </>
        ) : (
          <>
            <Col className="text-align-right">{imgToShow}</Col>
            <Col className="text">{textToShow}</Col>
          </>
        )}
      </div>
    </Section2Styled>
  );
};

const Section2Styled = styled.div`
  .text {
    margin: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: ${colors.textColor};
    margin: ${(props) => (props.screens.sm ? "0 25px" : "0 100px")};
  }
`;

export default Section2;
