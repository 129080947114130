import { Container } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../helpers/images";

const footerList = [
  {
    id: 0,
    title: "BERTONE DESIGN",
    para1: "Via Aspromonte 45",
    para2: "20900 MONZA (MB)",
    tel: "+39 0283419011",
    mail: "info@bertonedesign.it",
    iva: "08251740968",
  },
  {
    id: 1,
    title: "NEW CRAZY COLORS",
    para1: "Via Aspromonte 45",
    para2: "20900 MONZA (MB)",
    tel: "+39 0392028367",
    mail: "info@newcrazycolors.it",
    iva: "0294030963",
  },
];

const Footer = () => {
  const screens = useWindowMediaQuery();

  return (
    <FooterStyled screens={screens}>
      <Container className="pt-5 pb-5">
        <div className="footer-list">
          {footerList.map((list) => (
            <div key={list.id}>
              <h3 className="footer-heading">{list.title}</h3>
              <p className="footer-para">{list.para1}</p>
              <p className="footer-para">{list.para2}</p>
              <p className="footer-para">
                Tel: <span className="footer-para-color">{list.tel}</span>
              </p>
              <p className="footer-para">
                Mail: <span className="footer-para-color">{list.mail}</span>
              </p>
              <br />
              <br />
              <p className="footer-para">P.IVA: {list.iva}</p>
            </div>
          ))}
        </div>
      </Container>
      {screens.sm && (
        <IconsStyled>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/bertonedesign/"
          >
            <FacebookIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/bertone-design-italia/"
          >
            <LinkedinIcon />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/bertone_design"
          >
            <TwitterIcon />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/bertonedesignmilano/"
          >
            <InstagramIcon />
          </a>
        </IconsStyled>
      )}
    </FooterStyled>
  );
};

const IconsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  > a {
    margin-right: 30px;
  }
  > a:last-child {
    margin-right: 0px;
  }
`;

const FooterStyled = styled.div`
  background-color: ${colors.textColor};
  font-style: normal;
  .footer-list {
    display: flex;
    flex-wrap: wrap;
    text-align: ${(props) => (props.screens.sm ? "center" : "")};
    justify-content: ${(props) => (props.screens.sm ? "center" : "")};
  }
  .footer-heading {
    font-family: Roboto;
    font-weight: 700;
    font-size: 32px;
    line-height: 90.6%;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: ${colors.white};
    margin-bottom: 20px;
    margin-right: ${(props) => (props.screens.sm ? "" : "55px")};
  }
  .footer-para {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 90.6%;
    letter-spacing: -0.06em;
    color: ${colors.white};
  }
  .footer-para-color {
    color: ${colors.footerYellow};
  }
`;

export default Footer;
