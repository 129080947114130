const colors = {
  bgColor: "#E5E5E5;",
  headingColor: "#3f3d56",
  headingHighlight: "#861a56",
  textColor: "#575756",
  white: "#ffffff",
  footerYellow: "#ffcb08",
  modalBtn: "#5865f2",
  black: "#000000",
  grayText: "#3D3D3D",
};

export { colors };
