import PropTypes from "prop-types";

const Option = ({
  name,
  header,
  icon,
  link = null,
  active = false,
  onClick = () => {},
}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-start">
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={icon} className="modal-icons" alt={`${name}-icon`} />
        </a>
      ) : (
        <div onClick={onClick} className="pcursor">
          <img src={icon} className="modal-icons" alt={`${name}-icon`} />
        </div>
      )}
      <p className="modal-subtitle d-flex align-items-center text-center">
        {active && <div className="success-circle"></div>}
        {header}
      </p>
    </div>
  );
};

export default Option;
Option.propTypes = {
  name: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.string || null,
  active: PropTypes.bool || false,
  subheader: PropTypes.string,
  onClick: PropTypes.func,
};
