import { constructSameAddressMap } from "../utils/constructSameAddressMap";
import { SupportedChainId } from "./chains";

export const USDT_ADDRESS = {
  [SupportedChainId.POLYGON]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  [SupportedChainId.RINKEBY]: "0x33d18EC5833Dc43c32e35dd2201B13C500E3e008",
  [SupportedChainId.POLYGON_MUMBAI]:
    "0xff26b3d529b2ec7292abfc819ff4a1c439945c49",
};

export const TOKEN_ADDRESS = {
  [SupportedChainId.POLYGON]: "0xdB7344402AeCdFDf81C0c59F70DB147Fb7f5dc8C",
  [SupportedChainId.RINKEBY]: "0x7C22F3F24049e8006D0e3B164ECf6d451d283899",
  [SupportedChainId.POLYGON_MUMBAI]:
    "0x162de9801d2447cf77a78276b75700952e2096f9",
};

export const MULTICALL_ADDRESS = {
  ...constructSameAddressMap("0x1F98415757620B543A52E61c46B32eB19261F984", [
    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,
  ]),
};
