import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { CrossIcon } from "../../helpers/images";
import { useWeb3React } from "@web3-react/core";
import { ConfirmationPendingContent } from "./ConfirmationPendingContent";
import { TransactionSubmittedContent } from "./TransactionSubmittedContent";
import { TransactionErrorContent } from "./TransactionErrorContent";

// interface TransactionConfirmationModal {
//   isOpen: boolean
//   onDismiss: () => void
//   hash: string | undefined
//   attemptingTxn: boolean
//   pendingText: ReactNode
// }
const TransactionConfirmationModal = ({
  isOpen,
  onDismiss = () => {},
  attemptingTxn,
  hash,
  errorText = null,
}) => {
  const { chainId } = useWeb3React();

  if (!chainId) return null;

  return (
    <Modal className="my-modal" centered show={isOpen} onHide={onDismiss}>
      <Modal.Header style={{ border: "none", color: "#fff" }}>
        <p className="hidden">hide</p>
        <CrossIcon className="pcursor" onClick={onDismiss} />
      </Modal.Header>

      {attemptingTxn ? (
        <ConfirmationPendingContent />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
        />
      ) : (
        <TransactionErrorContent onDismiss={onDismiss} message={errorText} />
      )}
    </Modal>
  );
};

export default TransactionConfirmationModal;
TransactionConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  attemptingTxn: PropTypes.bool,
  hash: PropTypes.string || null,
  errorText: PropTypes.node || null,
};
