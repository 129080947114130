import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { updateNotActiveAndError, updateTriedEager } from "./slice";
import { useEagerConnect, useInactiveListener } from "../../hooks/web3";

export default function Updater() {
  const { active, error } = useWeb3React();

  const dispatch = useDispatch();

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  useEffect(() => {
    // on page load, do nothing until we've tried to connect to the injected connector
    dispatch(
      updateTriedEager({
        triedEager: triedEager,
      })
    );

    dispatch(
      updateNotActiveAndError({
        notActiveAndError: !active && error ? true : false,
      })
    );
  }, [dispatch, triedEager, active, error]);

  return null;
}
