import { configureStore } from "@reduxjs/toolkit";
import { applicationReducer } from "./application/slice";
import { multicallReducer } from "./multicall/slice";
import { web3ManagerReducer } from "./web3Manager/slice";

const store = configureStore({
  reducer: {
    application: applicationReducer,
    multicall: multicallReducer,
    web3Manager: web3ManagerReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
});

export default store;
