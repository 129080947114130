import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BASE_URI } from "../constants/misc";
import { useTokenContract } from "../hooks/useContract";
import { useSingleCallResult } from "../state/multicall/hooks";

export function useTokenContractOwner() {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(false);

  const ownerCall = useSingleCallResult(contract, "owner", undefined);
  // console.log("ownerCall >>> ", ownerCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (ownerCall.loading) return null;
    if (ownerCall.result) {
      return ownerCall.result?.[0];
    }
    return undefined;
  }, [chainId, ownerCall]);
}

export function useTokensPrice(noOfTokens) {
  const { chainId } = useWeb3React();
  const contract = useTokenContract(false);

  const inputs = useMemo(() => [noOfTokens], [noOfTokens]);
  const tokensPriceCall = useSingleCallResult(contract, "tokensPrice", inputs);
  // console.log("tokensPriceCall >>> ", tokensPriceCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (tokensPriceCall.loading) return null;
    if (tokensPriceCall.result) {
      return tokensPriceCall.result?.[0];
    }
    return undefined;
  }, [chainId, tokensPriceCall]);
}

export function useHasClaimed() {
  const { chainId, account } = useWeb3React();
  const contract = useTokenContract(false);

  const inputs = useMemo(() => [account], [account]);
  const claimsCall = useSingleCallResult(contract, "claims", inputs);
  // console.log("claimsCall >>> ", claimsCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (claimsCall.loading) return null;
    if (claimsCall.result) {
      return claimsCall.result?.[0];
    }
    return undefined;
  }, [chainId, claimsCall]);
}

export function useProofHashData() {
  const [state, setState] = useState(null);
  const { chainId } = useWeb3React();
  const contract = useTokenContract(false);

  const proofHashCall = useSingleCallResult(contract, "proofHash", undefined);

  const hash = useMemo(() => {
    if (!chainId) return undefined;
    if (proofHashCall.loading) return null;
    if (proofHashCall.result) {
      return proofHashCall.result?.[0];
    }
    return undefined;
  }, [chainId, proofHashCall]);

  const hashCallback = useCallback(async () => {
    if (!hash) return undefined;

    const response = await axios.get(`${BASE_URI}${hash}`);
    if (!response?.data) return undefined;

    setState(response.data);
  }, [hash]);

  useEffect(() => {
    let mounted = true;
    if (mounted) hashCallback();

    return () => {
      mounted = false;
    };
  }, [hashCallback]);

  return useMemo(() => {
    return {
      merkleRoot: state ? state?.merkleRoot : "",
      totalCount: state ? state?.totalCount : 0,
      claims: state ? state?.claims : {},
    };
  }, [state]);
}

export function useProof() {
  const { account, chainId } = useWeb3React();

  const { claims } = useProofHashData();

  return useMemo(() => {
    // undefined means wallet is not connected
    if (!account || !chainId) return undefined;

    const proofObj = claims[account];

    // null means account is not eligible
    if (!proofObj) return null;

    return {
      index: proofObj?.index,
      proof: proofObj?.proof,
    };
  }, [account, chainId, claims]);
}
