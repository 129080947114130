// (uncheckedAddresses?: (string | undefined)[]): {
//   [address: string]: CurrencyAmount<Currency> | undefined
// }

import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { useMulticall } from "../../hooks/useContract";
import { isAddress } from "../../utils";
import { toBN } from "../../utils/format";
import { useSingleContractMultipleData } from "../multicall/hooks";

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(uncheckedAddresses = []) {
  const { chainId } = useWeb3React();
  const multicallContract = useMulticall();

  const addresses = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a) => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  );

  const results = useSingleContractMultipleData(
    multicallContract,
    "getEthBalance",
    addresses.map((address) => [address])
  );

  return useMemo(
    () =>
      addresses.reduce((memo, address, i) => {
        const value = results?.[i]?.result?.[0];
        if (value && chainId) memo[address] = value.toString();
        return memo;
      }, {}),
    [addresses, chainId, results]
  );
}

export function useETHBalance() {
  const { account } = useWeb3React();
  const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? ""];
  return useMemo(() => account && toBN(ethBalance ?? 0), [account, ethBalance]);
}
