import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useTokenContractOwner } from "../data/token";
import isZero from "../utils/isZero";
import { useTokenContract } from "./useContract";
import { getSigner } from "../utils";
import { calculateGasMargin } from "../utils/calculateGasMargin";
import { useETHBalance } from "../state/wallet/hooks";

export const SetRootState = {
  ELIGIBLE: "ELIGIBLE",
  NOT_ELIGIBLE: "NOT_ELIGIBLE",
  INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
  UNKNOWN: "UNKNOWN",
};

export const useSetRootCallback = (root, ipfsHash) => {
  const { chainId, account, library } = useWeb3React();

  const ethBalance = useETHBalance();
  const owner = useTokenContractOwner();

  const setRootState = useMemo(() => {
    // if ethBalance not present
    if (!ethBalance) return SetRootState.UNKNOWN;

    // if account is not owner
    if (account !== owner) return SetRootState.NOT_ELIGIBLE;

    // if ethBalance is zero
    if (isZero(ethBalance.toHexString()))
      return SetRootState.INSUFFICIENT_BALANCE;

    return SetRootState.ELIGIBLE;
  }, [ethBalance, account, owner]);

  const contract = useTokenContract(false);

  const setRoot = useCallback(async () => {
    if (!chainId) {
      console.error("no chainId");
      return;
    }

    if (!contract) {
      console.error("contract is null");
      return;
    }

    if (!root) {
      console.error("root is null");
      return;
    }

    if (!ipfsHash) {
      console.error("ipfsHash is null");
      return;
    }

    const signer = getSigner(library, account);

    const estimatedGas = await contract
      .connect(signer)
      .estimateGas.setRoot(root, ipfsHash);

    // actual txn
    const response = await contract.connect(signer).setRoot(root, ipfsHash, {
      gasLimit: calculateGasMargin(estimatedGas),
    });

    return response;
  }, [chainId, contract, library, account, root, ipfsHash]);

  return [setRootState, setRoot];
};
