import styled from "styled-components";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import { colors } from "../helpers/colors";
import { shortenAddress } from "../utils";
import { TOKEN_ADDRESS } from "../constants/addresses";
import { SupportedChainId } from "../constants/chains";
import { ExplorerDataType, getExplorerLink } from "../utils/getExplorerLink";

const Section5 = () => {
  const screens = useWindowMediaQuery();
  return (
    <Section5Styled screens={screens}>
      <div className="text-center">
        <h1>COLLECTION DETAILS</h1>
        <div className="mt-5 mb-5 collection">
          <div className="collection-details">
            <div>500</div>
            <div>NFTS</div>
          </div>
          <div className="collection-details">
            <div>200</div>
            <div>
              USDT<small className="small">EACH</small>
            </div>
          </div>
          <div className="collection-details other-details">
            <div>BLOCKCHAIN</div>
            <div>POLYGON</div>
          </div>
          <div className="collection-details other-details">
            <div>CONTRACT ADDRESS</div>
            <div>
              <a
                href={getExplorerLink(
                  SupportedChainId.POLYGON,
                  TOKEN_ADDRESS[SupportedChainId.POLYGON],
                  ExplorerDataType.ADDRESS
                )}
                target="_blank"
                rel="noopener noreferrer"
                className="no-link"
              >
                {shortenAddress(TOKEN_ADDRESS[SupportedChainId.POLYGON])}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section5Styled>
  );
};

const Section5Styled = styled.div`
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => (props.screens.sm ? "30px" : "56px")};
    color: ${colors.black};
  }
  .collection {
    display: flex;
    justify-content: center;
    align-items: ${(props) => (props.screens.sm ? "center" : "flex-end")};
    flex-wrap: wrap;
    flex-direction: ${(props) => (props.screens.sm ? "column" : "row")};
    gap: ${(props) => (props.screens.sm ? "20px" : "80px")};
    color: ${colors.grayText};
  }
  .collection-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.grayText};
    font-family: Poppins;
    font-style: normal;
    font-size: ${(props) => (props.screens.sm ? "30px" : "56.8421px")};
    > div:first-child {
      font-weight: 500;
    }
    >div: nth-child(2) {
      font-weight: bold;
    }
  }
  .other-details {
    > div:first-child {
      font-size: ${(props) => (props.screens.sm ? "17px" : "37.84px")};
      margin-bottom: ${(props) => (props.screens.sm ? "10px" : "20px")};
    }
  }
  .small {
    font-size: ${(props) => (props.screens.sm ? "14px" : "24.84px")};
    font-weight: 300;
  }
  .no-link {
    color: ${colors.grayText};
  }
`;

export default Section5;
