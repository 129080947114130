import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useHasClaimed, useProof } from "../data/token";
import isZero from "../utils/isZero";
import { useTokenContract } from "./useContract";
import { getSigner } from "../utils";
import { calculateGasMargin } from "../utils/calculateGasMargin";
import { useETHBalance } from "../state/wallet/hooks";

export const ClaimState = {
  ELIGIBLE: "ELIGIBLE",
  NOT_ELIGIBLE: "NOT_ELIGIBLE",
  CLAIMED: "CLAIMED",
  INSUFFICIENT_ETH: "INSUFFICIENT_ETH",
  UNKNOWN: "UNKNOWN",
};

export const useClaimCallback = () => {
  const { chainId, account, library } = useWeb3React();

  const ethBalance = useETHBalance();
  const hasClaimed = useHasClaimed();
  const proofObj = useProof();
  const proof = useMemo(() => proofObj?.proof, [proofObj]);

  const claimState = useMemo(() => {
    if (proof === undefined || !ethBalance) return ClaimState.UNKNOWN;

    if (isZero(ethBalance.toHexString())) return ClaimState.INSUFFICIENT_ETH;

    if (proof === null) return ClaimState.NOT_ELIGIBLE;

    if (hasClaimed) return ClaimState.CLAIMED;

    return ClaimState.ELIGIBLE;
  }, [proof, ethBalance, hasClaimed]);

  const contract = useTokenContract(false);

  const claim = useCallback(async () => {
    if (!chainId) {
      console.error("no chainId");
      return;
    }

    if (!contract) {
      console.error("contract is null");
      return;
    }

    const signer = getSigner(library, account);

    const estimatedGas = await contract
      .connect(signer)
      .estimateGas.claim(proof);

    // actual txn
    const response = await contract.connect(signer).claim(proof, {
      gasLimit: calculateGasMargin(estimatedGas),
    });

    return response;
  }, [chainId, contract, library, account, proof]);

  return [claimState, claim];
};
