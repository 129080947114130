import { useWeb3React } from "@web3-react/core";
import { useEffect, useRef } from "react";
import Jazzicon from "@metamask/jazzicon";
import PropTypes from "prop-types";

const IdentIcon = ({ diameter = 16 }) => {
  const ref = useRef();

  const { account } = useWeb3React();

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = "";
      ref.current.appendChild(
        Jazzicon(diameter, parseInt(account.slice(2, 10), 16))
      );
    }
  }, [account, diameter]);

  return <div ref={ref} />;
};

export default IdentIcon;
IdentIcon.propTypes = {
  diameter: PropTypes.number,
};
