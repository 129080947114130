import styled from "styled-components";
import { Col } from "react-bootstrap";
import { bike3 } from "../helpers/images";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const Section3 = () => {
  const screens = useWindowMediaQuery();
  return (
    <Section3Styled screens={screens}>
      <div className="d-flex align-items-center flex-wrap">
        <Col className="text mt-5 mb-4">
          The bike, 161cm long and 89cm high, is versatile and can be used by
          both women and men. <br /> Available in different colors, much lighter
          than a standard e-bike. Lib.ike is an ideal vehicle for today&lsquo;s
          city life
        </Col>
        <Col className="text-align-right">
          <img src={bike3} className="img" alt="bike3" />
        </Col>
      </div>
    </Section3Styled>
  );
};

const Section3Styled = styled.div`
  .text {
    margin: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    color: ${colors.textColor};
    margin: ${(props) => (props.screens.sm ? "0 25px" : "0 100px")};
  }
`;

export default Section3;
