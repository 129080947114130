import { Link, MetaMask, WalletConnect, CoinBase } from "../../helpers/images";
import { useWeb3React } from "@web3-react/core";
import { walletList } from "../../constants/wallet";
import { injected, walletconnect, walletlink } from "../../connectors";
import { shortenAddress } from "../../utils";
import IdentIcon from "../IdentIcon";
import CopyHelper from "../CopyHelper";
import { ExplorerDataType, getExplorerLink } from "../../utils/getExplorerLink";

const AccountDetails = () => {
  const { chainId, account, connector } = useWeb3React();

  function formatConnectorName() {
    const { ethereum } = window;
    const isMetaMask = !!(ethereum && ethereum.isMetaMask);
    const name = walletList
      .filter(
        (option) =>
          option.connector === connector &&
          (connector !== injected ||
            isMetaMask === (option.name === "Metamask"))
      )
      .map((option) => option.name)[0];
    return `Connected with ${name}`;
  }

  function getWalletIcon() {
    if (connector === injected) {
      return MetaMask;
    } else if (connector === walletconnect) {
      return WalletConnect;
    } else if (connector === walletlink) {
      return CoinBase;
    }
  }

  function getStatusIcon() {
    if (connector === injected) {
      return <IdentIcon diameter={20} />;
    } else if (connector === walletconnect) {
      return <img src={WalletConnect} alt={""} />;
    } else if (connector === walletlink) {
      return <img src={CoinBase} alt={""} />;
    }
  }

  return (
    <>
      <div className="d-flex text-start justify-content-center align-items-center text-center">
        <img src={getWalletIcon()} alt="wallet" className="modal-icons" />
        <h5 className="modal-title">{formatConnectorName()}</h5>
      </div>
      <div className="modal-body d-flex justify-content-evenly align-items-center">
        <div className="modal-subtitle d-flex">
          <div style={{ marginRight: "10px" }}>{getStatusIcon()}</div>
          <div>{shortenAddress(account)}</div>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <div className="pcursor short-text">
            <CopyHelper toCopy={account}>Copy Address</CopyHelper>
          </div>
          <div className="short-text">
            <a
              href={getExplorerLink(chainId, account, ExplorerDataType.ADDRESS)}
              target="_blank"
              rel="noopener noreferrer"
              className="short-text"
            >
              <img src={Link} alt="View on Explorer" /> View on Explorer
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
