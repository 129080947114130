import styled from "styled-components";
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  MintSection,
} from "../components";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const LandingPage = () => {
  const screens = useWindowMediaQuery();
  return (
    <LandingPageStyled screens={screens}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <MintSection />
    </LandingPageStyled>
  );
};

const LandingPageStyled = styled.div`
  .img {
    overflow: hidden;
    width: calc(100vw);
    max-width: ${(props) => (props.screens.sm ? "" : "100%")};
  }
`;

export default LandingPage;
