import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { useUsdtContract } from "../hooks/useContract";
import { useSingleCallResult } from "../state/multicall/hooks";

export function useUsdtAllowance(owner, spender) {
  const { chainId } = useWeb3React();
  const contract = useUsdtContract(false);

  const inputs = useMemo(() => [owner, spender], [owner, spender]);
  const allowanceCall = useSingleCallResult(contract, "allowance", inputs);
  // console.log("allowanceCall >>> ", allowanceCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (allowanceCall.loading) return null;
    if (allowanceCall.result) {
      return allowanceCall.result?.[0];
    }
    return undefined;
  }, [chainId, allowanceCall]);
}

export function useUsdtBalance() {
  const { chainId, account } = useWeb3React();
  const contract = useUsdtContract(false);

  const inputs = useMemo(() => [account], [account]);
  const balanceCall = useSingleCallResult(contract, "balanceOf", inputs);
  // console.log("balanceCall >>> ", balanceCall);

  return useMemo(() => {
    if (!chainId) return undefined;
    if (balanceCall.loading) return null;
    if (balanceCall.result) {
      return balanceCall.result?.[0];
    }
    return undefined;
  }, [chainId, balanceCall]);
}
