import styled from "styled-components";
import { Col } from "react-bootstrap";
import { bike1 } from "../helpers/images";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const Section1 = () => {
  const screens = useWindowMediaQuery();

  return (
    <Section1Styled screens={screens}>
      <div className="section1">
        <Col md={1}></Col>
        <Col>
          <h1 className="heading">
            <span className="highlightedText">LIB.IKE</span> THE SUSTAINABLE AND
            ADVANCED VEHICLE TO MOVE IN THE CITY OF THE FUTURE
          </h1>
        </Col>
        <Col>
          <img src={bike1} className="img" alt="bike1" />
        </Col>
      </div>
    </Section1Styled>
  );
};

const Section1Styled = styled.div`
  .section1 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => (props.screens.sm ? "20px" : "4vw")};
    line-height: ${(props) => (props.screens.sm ? "30px" : "4vw")};
    margin: ${(props) => (props.screens.sm ? "40px 20px" : "")};
    color: ${colors.headingColor};
  }

  .highlightedText {
    font-weight: 900;
    color: ${colors.headingHighlight};
  }

  .img {
    overflow: hidden;
    width: calc(100vw);
    max-width: ${(props) => (props.screens.sm ? "" : "100%")};
  }
`;

export default Section1;
