import styled from "styled-components";

export const ModalStyled = styled.div`
  .modal-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => (props.screens.sm ? "22px" : "42px")};
    text-align: center;
    line-height: 155.6%;
    letter-spacing: -0.06em;
    color: #ffffff;
    .title-color {
      color: #fc9c49;
    }
    .error {
      color: #fe5b4a;
      font-size: ${(props) => (props.screens.sm ? "22px" : "42px")};
    }
  }

  .modal-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: ${(props) => (props.screens.sm ? "16px" : "22px")};
    line-height: 155.6%;
    text-align: left;
    letter-spacing: -0.06em;
    color: #ffffff;
    .sub-title {
      font-weight: 700;
    }
  }
  .short-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17.4917px;
    line-height: 20px;
    margin: 10px;
    color: #ffffff;
    text-decoration: none;
  }
`;

export const ModalButton = styled.button`
  background: none;
  border: none;
  outline: inherit;
  width: ${(props) => (props.screens.sm ? "200px" : "320px")};
  padding: ${(props) => (props.small ? "3px" : "10px 20px")};
  background: #5865f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20.423px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.screens.sm ? "16px" : "24px")};
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  cursor: pointer;
`;
