import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "../utils";
import {
  USDT_ADDRESS,
  TOKEN_ADDRESS,
  MULTICALL_ADDRESS,
} from "../constants/addresses";
import MULTICALL_ABI from "../abis/multicall.json";
import TOKEN_ABI from "../abis/token.json";
import USDT_ABI from "../abis/usdt.json";

// returns null on errors
// addressOrAddressMap -> string | { [chainId: number]: string } | undefined
export const useContract = (addressOrAddressMap, ABI, withSignerIfPossible) => {
  const { library, account, chainId } = useWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address; // string | undefined
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;

    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    library,
    account,
    chainId,
    addressOrAddressMap,
    ABI,
    withSignerIfPossible,
  ]);
};

export const useMulticall = () => {
  return useContract(MULTICALL_ADDRESS, MULTICALL_ABI, false);
};

export const useUsdtContract = (withSignerIfPossible = true) => {
  return useContract(USDT_ADDRESS, USDT_ABI, withSignerIfPossible);
};

export const useTokenContract = (withSignerIfPossible = true) => {
  return useContract(TOKEN_ADDRESS, TOKEN_ABI, withSignerIfPossible);
};
