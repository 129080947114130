import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ErrorPage from "./pages/ErrorPage";
import AdminPage from "./pages/AdminPage";
import { Footer, Header } from "./components";
import { colors } from "./helpers/colors";
import styled from "styled-components";
import "./styles.css";

const App = () => {
  return (
    <AppStyled>
      <Header />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </AppStyled>
  );
};

const AppStyled = styled.div`
  background-color: ${colors.bgColor};
`;

export default App;
