import { useSelector } from "react-redux";

// returns triedEager boolean value
export function useTriedEager() {
  return useSelector((state) => state.web3Manager.triedEager);
}

// returns notActiveAndError boolean value
export function useNotActiveAndError() {
  return useSelector((state) => state.web3Manager.notActiveAndError);
}
