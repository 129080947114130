import { Modal } from "react-bootstrap";
import { CrossIcon } from "../../helpers/images";
import { ModalStyled } from "./ModalStyled";
import useWindowMediaQuery from "../../helpers/useMediaQueryHook";
import { useEffect, useState } from "react";
import {
  useWalletModalOpen,
  useWalletModalToggle,
} from "../../state/application/hooks";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import usePrevious from "../../hooks/usePrevious";
import { injected } from "../../connectors";
import { walletList } from "../../constants/wallet";
import PendingView from "./PendingView";
import Option from "./Option";
import AccountDetails from "./AccountDetails";

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
  PENDING: "pending",
};

const WalletModal = () => {
  const screens = useWindowMediaQuery();
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [pendingWallet, setPendingWallet] = useState();
  const [pendingError, setPendingError] = useState();

  const walletModalOpen = useWalletModalOpen();
  const toggleWalletModal = useWalletModalToggle();

  const { activate, connector, account, active, error } = useWeb3React();

  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  const tryActivation = async (connector) => {
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect,
    // manually reset the connector
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((e) => {
        // const err = getErrorMessage(e);
        console.error("ERROR activateWallet -> ", e);
        if (e instanceof UnsupportedChainIdError) {
          // a little janky...can't use setError because the connector isn't set
          activate(connector);
        } else {
          setPendingError(true);
        }
      });
  };

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    return (
      <>
        <div className="text-center modal-title">Connect Your Wallet</div>
        <div className="modal-body d-flex justify-content-evenly">
          {walletList.map((option, i) => {
            // overwrite injected when needed
            if (option.connector === injected) {
              // don't show injected if there's no injected provider
              if (!(window.web3 || window.ethereum)) {
                return null; //don't want to return metamask twice
              }
            }

            // return rest of options
            return (
              <Option
                key={i}
                name={option.name}
                header={option.header}
                icon={option.icon}
                link={option.link}
                active={option.connector && option.connector === connector}
                onClick={async () =>
                  option.connector === connector
                    ? setWalletView(WALLET_VIEWS.ACCOUNT)
                    : !option.href && tryActivation(option.connector)
                }
              />
            );
          })}
        </div>
      </>
    );
  }

  function getModalBodyContent() {
    if (error) {
      return (
        <>
          <div className="text-center modal-title">
            {error instanceof UnsupportedChainIdError
              ? "Wrong Network"
              : "Error connecting"}
          </div>
          <div className="modal-body d-flex justify-content-evenly">
            <h5>
              {error instanceof UnsupportedChainIdError
                ? "Please connect to Polygon network."
                : "Try refreshing the page."}
            </h5>
          </div>
        </>
      );
    }

    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return <AccountDetails />;
    }

    return walletView === WALLET_VIEWS.PENDING ? (
      <PendingView
        connector={pendingWallet}
        error={pendingError}
        setPendingError={setPendingError}
        tryActivation={tryActivation}
      />
    ) : (
      getOptions()
    );
  }

  // main modal return
  return (
    <Modal
      dialogClassName="modal-lg"
      className="my-modal"
      centered
      show={walletModalOpen}
      onHide={toggleWalletModal}
    >
      {/* Absolute Right side Close Icon */}
      <Modal.Header style={{ border: "none", color: "#fff" }}>
        {account && walletView === WALLET_VIEWS.ACCOUNT ? (
          <p
            onClick={() => setWalletView(WALLET_VIEWS.OPTIONS)}
            className="pcursor mb-0 mt-2"
          >
            Change
          </p>
        ) : (
          <p className="hidden">hide</p>
        )}
        <CrossIcon className="pcursor" onClick={toggleWalletModal} />
      </Modal.Header>

      <ModalStyled screens={screens}>
        <Modal.Body className="text-center">{getModalBodyContent()}</Modal.Body>
      </ModalStyled>
    </Modal>
  );
};

export default WalletModal;
