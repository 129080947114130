export const SupportedChainId = {
  // polygon mainnet
  POLYGON: 137,

  // testnets
  RINKEBY: 4,
  POLYGON_MUMBAI: 80001,
};

export const ALL_SUPPORTED_CHAIN_IDS = [
  SupportedChainId.POLYGON,
  SupportedChainId.RINKEBY,
  SupportedChainId.POLYGON_MUMBAI,
];
