import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ModalButton, ModalStyled } from "../WalletModal/ModalStyled";
import useWindowMediaQuery from "../../helpers/useMediaQueryHook";

export function TransactionErrorContent({
  onDismiss = () => {},
  message = null,
}) {
  const screens = useWindowMediaQuery();

  return (
    <ModalStyled screens={screens}>
      <div className="modal-title">
        <div className="text-center">Oops</div>
      </div>
      <Modal.Body className="text-center">
        <div className="modal-subtitle text-center">
          {message ? message : "Transaction Rejected."}
        </div>
        <ModalButton
          screens={screens}
          className="mt-5 mx-auto"
          onClick={onDismiss}
        >
          Try Again
        </ModalButton>
      </Modal.Body>
    </ModalStyled>
  );
}
TransactionErrorContent.propTypes = {
  onDismiss: PropTypes.func,
  message: PropTypes.node || null,
};
