import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "../../helpers/images";
import { ExplorerDataType, getExplorerLink } from "../../utils/getExplorerLink";
import { ModalButton, ModalStyled } from "../WalletModal/ModalStyled";
import useWindowMediaQuery from "../../helpers/useMediaQueryHook";

export function TransactionSubmittedContent({
  onDismiss = () => {},
  chainId,
  hash,
}) {
  const screens = useWindowMediaQuery();

  return (
    <Modal.Body className="text-center">
      <ModalStyled screens={screens}>
        <div className="modal-subtitle text-center mb-2">
          Transaction Submitted
        </div>
        <span className="short-text">
          <a
            href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
            target="_blank"
            rel="noopener noreferrer"
            className="short-text"
          >
            <img style={{ width: "24px", marginRight: "20px" }} src={Link} />
            View on Explorer
          </a>
        </span>
        <ModalButton
          className="mt-2 mb-3 mx-auto"
          onClick={onDismiss}
          screens={screens}
        >
          Dismiss
        </ModalButton>
      </ModalStyled>
    </Modal.Body>
  );
}
TransactionSubmittedContent.propTypes = {
  onDismiss: PropTypes.func,
  chainId: PropTypes.number,
  hash: PropTypes.string,
};
