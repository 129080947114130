import { SupportedChainId } from "../constants/chains";

const DEFAULT_NETWORKS = [SupportedChainId.RINKEBY];

export function constructSameAddressMap(address, additionalNetworks = []) {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce((memo, chainId) => {
    memo[chainId] = address;
    return memo;
  }, {});
}
